.NavigationButton {
  position: absolute;
  top: 50%;
  z-index: 1;
  display: flex;
  justify-content: center;
  width: 2.375rem;
  height: 2.375rem;
  padding: 0.5rem;
  background-color: var(--color_guide-navigationButton-bg);
  opacity: 0.8;
  transform: translate3d(0, -50%, 0);
  transition: opacity 150ms linear;

  &--shouldHideOnMobile {
    display: none;

    @media (--mq-from-tablet) {
      display: flex;
    }
  }

  &:hover {
    opacity: 1;
  }

  &--previous {
    left: 0.625rem;
  }

  &--next {
    right: 0.625rem;
  }

  & svg {
    height: 100%;
    fill: var(--color_white);
  }

  &:global(.tv__focus) {
    background: var(--color_focus-border);
  }
}
