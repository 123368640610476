.contentRowGuide {
  display: flex;

  --sidePadding: 0.9375rem;
  @media (--mq-from-tablet) {
    --sidePadding: 1.5625rem;
  }
  @media (--mq-from-desktop) {
    --sidePadding: 3.4375rem;
  }
  @media (--mq-from-large-desktop) {
    --sidePadding: 3.125rem;
  }
  @mixin tvResize {
    --sidePadding: 3.125rem;
  }

  --HZL-list-side-padding: var(--sidePadding);

  &__header,
  &__rowWrapper {
    margin: 0 var(--sidePadding);
  }

  &__rowWrapper {
    display: flex;
    width: 100%;
    white-space: nowrap;
  }
}

.contentRowGuide > * {
  width: 100%;
}
