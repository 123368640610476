.daySelector {
  --daySelector_fontSize: 1rem;
  --daySelector_fontSize-mobile: 0.875rem;

  background-color: var(--color_guide-day-selector-bg);

  &__item {
    @mixin responsiveGrid contentRow;
    @mixin responsiveGrid daySelectorTv, true;

    position: relative;
    display: inline-block;
    vertical-align: top;
    color: var(--color_guide-day-selector-item-fg);
    text-align: center;
  }

  &__button {
    @mixin tvDevice {
      --daySelector_fontSize: 1.75rem;
      width: 100%;
      color: var(--color_white);

      &[data-tv-focus] {
        color: var(--color_white);
        background: var(--color_focus-border);
        border-radius: 0.3125rem;
      }

      &:hover {
        color: var(--color_guide-day-selector-item-hover-fg);

        &:not([data-tv-focus]) {
          color: var(--color_guide-day-selector-item-hover-fg);
        }
      }

      &--active {
        color: var(--color_guide-day-selector-item-active-fg);

        &[data-tv-focus] {
          color: var(--color_white);
        }

        &:not([data-tv-focus]) {
          color: var(--color_guide-day-selector-item-active-fg);
        }
      }
    }

    &:hover,
    &--active {
      color: var(--color_guide-day-selector-item-active-fg);
    }

    &__day {
      display: block;
      padding: 1rem 0 0;
      font-family: var(--font_hind);
      font-size: var(--daySelector_fontSize-mobile);
      font-weight: 600;
      @media (--mq-from-tablet) {
        font-size: var(--daySelector_fontSize);
      }
    }

    &__date {
      display: block;
      padding: 0 0 0.9375rem;
      font-size: var(--daySelector_fontSize-mobile);
      @media (--mq-from-tablet) {
        font-size: var(--daySelector_fontSize);
      }
    }

    &:disabled {
      color: var(--color_text-disabled);
      cursor: auto;

      @mixin tvDevice {
        color: var(--color_white);
      }
    }
  }
}
